import templateUrl from './session.view.html'

/**
 * Production Order detail Report route
 *
 * @namespace routes.ReportsessionView
 * @type {TmrRoute}
 */
export const route = {
  name: 'report.sessions.view',
  label: 'REPORT.SESSIONS.VIEW.TITLE',
  component: 'tmrWorkLogSessionView',
  accessControl: [
    'Loglavorazione.capability.list',
    'Loglavorazione.capability.admin',
  ],
  abstract: false,
  paging: true,
  resolve: {
    sessionMovementRows: () => {},
    sessionMovementRowsCount: () => {},
  },
}

/**
 * Component for {@link routes.tmrWorkLogSessionView sessions Report route}
 *
 * @type {ngComponent}
 */
export const tmrWorkLogSessionView = {
  ngType: 'component',
  templateUrl,
  controller: 'customTmrWorkSessionController',
  bindings: {
    resource: '<',
    currentPage: '<',
    pageSize: '<',
  },
}

/**
 * Controller of component {@link customTmrWorkSessionController}.
 *
 * @type {ngController}
 */
export class customTmrWorkSessionController {
  static ngType = 'controller'
  constructor($state, $mdDialog, TmrToast, TmrPromptModal) {
    'ngInject'
    Object.assign(this, {
      $state,
      $mdDialog,
      TmrToast,
      TmrPromptModal,
    })
  }
}
