/**
 * Resource that represents a Economia.
 *
 * @name Economia
 * @class
 * @augments TmrResource
 */
function EconomiaFactory(TmrResource) {
  'ngInject'
  let Economia = TmrResource('Economia')

  /**
   * Override default label property.
   *
   * @method Economia#getLabelProperty
   */
  function $getLabel() {
    return `${this.code} ${this.description}`
  }
  Object.assign(Economia.prototype, {
    $getLabel,
  })

  return Economia
}

export const factory = ['Economia', EconomiaFactory]
