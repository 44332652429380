import './login.scss'
import templateUrl from './login.html'
import logo from 'brioniAssets/images/brioniLogo.png'
import { tmrLoginController as coreTmrLoginController } from 'tmr/routes/login/login.controller'

/**
 * User login route.
 *
 * @namespace routes.Login
 * @type {TmrRoute}
 */
export const route = {
  name: 'login',
  component: 'customtmrLogin',
}

/**
 * User login component.
 *
 * @type {ngComponent}
 */
export const customtmrLogin = {
  ngType: 'component',
  templateUrl,
  controller: 'customTmrLoginController',
  bindings: {
    loggedUser: '<',
    loginAs: '<',
    onSubmit: '&?',
  },
}

export class customTmrLoginController extends coreTmrLoginController {
  constructor(
    $state,
    TmrApiAuth,
    TmrInvalidCredentialsError,
    TmrConnectionTimeoutError
  ) {
    'ngInject'
    super(
      $state,
      TmrApiAuth,
      TmrInvalidCredentialsError,
      TmrConnectionTimeoutError
    )
    // Override logo.
    this.logo = logo
    this.loginEnabled = false
    console.log(this.logo)
  }

  toggleLogin() {
    this.loginEnabled = !this.loginEnabled
    console.log('toggleLogin', this.loginEnabled)
  }
}
