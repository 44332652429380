import _ from 'lodash'
import './printMultipleModal.scss'
import templateUrl from './printMultipleModal.html'

/**
 * Configures a print Modal using $mdPanel
 *
 * @type {ngConfig}
 * @ngInject
 */
function tmrPrintMultipleModalConfig($mdPanelProvider) {
  $mdPanelProvider.definePreset('printMultipleModalCustom', {
    templateUrl,
    controller: 'tmrPrintMultipleModalCustomController',
    controllerAs: '$ctrl',
    panelClass: 'tmr-modal tmr-print-multiple-modal md-whiteframe-1dp',
    clickOutsideToClose: true,
    escapeToClose: true,
    hasBackdrop: true,
  })
}

/**
 * @type {ngFactory}
 * @ngInject
 */
function TmrPrintMultipleModalFactory($mdPanel, tmrLimitZeroWorkaround) {
  /**
   * Simplifies the usage of the `printMultipleModal` panel defined in {@link tmrPrintMultipleModalConfig}
   *
   * @example <caption>In a controller</caption>
   * this.TmrPrintMultipleModal($event, { resource: this.resource })
   *
   * @type {ngService}
   * @param {Event} openFrom
   * @param {object} options
   * @param {function} options.clearCallback
   * @param {function} options.removeCallback
   * @param {boolean} options.quantityColumn
   * @param {string} options.quantityFixProperty
   * @param {function=} options.onCloseCallback Callback that is executed when the modal closes
   *
   * @type {ngService}
   */
  return function TmrPrintMultipleModal(
    openFrom,
    {
      clearCallback,
      removeCallback,
      quantityColumn,
      quantityFixProperty,
      onCloseCallback,
    }
  ) {
    return $mdPanel.open('printMultipleModalCustom', {
      openFrom,
      position: $mdPanel.newPanelPosition().absolute().center(),
      onCloseSuccess: (MdPanelRef, reason) => {
        if (reason == 'PRINTMULTIPLEMODAL_CLEAR') {
          clearCallback()
        }
        onCloseCallback?.()
      },
      locals: { removeCallback, quantityColumn, quantityFixProperty },
      resolve: {
        printModalPrinters: /** @ngInject */ Printer =>
          Printer.query().$promise,
        resources: /** @ngInject */ ($injector, TmrPrint, TmrSerialQ) => {
          // Passed resources are stub objects from local storage: load them grouped by type.
          let groups = _.groupBy(TmrPrint.resourcesToPrint, resource =>
            resource.$getType()
          )
          let types = Object.keys(groups)
          types.forEach(
            type => (groups[type] = groups[type].map(({ id }) => id))
          )
          // Load groups serially and concatenate results in a single array.
          let loadedResources = []
          loadedResources.$promise = TmrSerialQ(types, type =>
            $injector
              .get(type)
              .advancedSearch({
                ids: groups[type],
                limit: tmrLimitZeroWorkaround,
              })
              .$promise.then(res => {
                if (res) loadedResources.push(...res)
                return res
              })
          )
          return loadedResources
        },
        quantityFixEnabled: /** @ngInject */ AppData =>
          quantityFixProperty
            ? AppData.getValue(quantityFixProperty).then(
                res => res && res.toUpperCase() == 'TRUE'
              )
            : false,
      },
    })
  }
}

/**
 * Controller for the panel preset printMultipleModal
 *
 * @type {ngController}
 * @see TmrPrintMultipleModal
 */
export class tmrPrintMultipleModalCustomController {
  static ngType = 'controller'

  doublePrint = false

  // Locals
  /** @type {boolean} */
  quantityFixEnabled
  /** @type {object[]} */
  resources
  /** @type {function} */
  removeCallback

  /** @ngInject */ constructor(TmrApi, TmrSerialQ, TmrToast, mdPanelRef) {
    this.TmrApi = TmrApi
    this.TmrSerialQ = TmrSerialQ
    this.TmrToast = TmrToast
    this.mdPanelRef = mdPanelRef
  }

  $onInit() {
    if (this.quantityFixEnabled)
      this.resources.forEach(res => (res.quantity = res.quantity ?? 1))
  }

  cancel() {
    return this.mdPanelRef.close()
  }

  clearList() {
    return this.mdPanelRef.close('PRINTMULTIPLEMODAL_CLEAR')
  }

  remove(resource) {
    this.resources.splice(
      this.resources.findIndex(res => res === resource),
      1
    )
    this.removeCallback(resource)
    if (!this.resources.length) return this.cancel()
  }

  onPrinterChange($printer) {
    this.printer = $printer
  }

  /**
   * Builds post data to be pased to a printBulk request
   *
   * @prop {array} resList The ids and quantities of the printed elements
   */
  printBulkPostData(resList) {
    let data = {}
    resList.forEach(
      ({ id, quantity }) => (data[id] = { printer: this.printer, quantity })
    )
    return data
  }

  print() {
    if (this.doublePrint) {
      this.resources.forEach(rs => (rs.quantity = 2))
    }
    this.printing = true
    // Group by type then do a separate print request for each group.
    let groups = _.groupBy(this.resources, resource => resource.$getType())
    let types = Object.keys(groups)

    return this.TmrSerialQ(types, async type => {
      let resList = groups[type].map(({ id, quantity }) => {
        return { id, quantity: quantity ?? 1 }
      })

      if (this.labelQuantity > 1) {
        resList = Array(this.labelQuantity).fill(resList).flat()
      }

      await this.printMultipleTimes(type, resList, this.labelQuantity)
    })
      .then(() => this.printSuccess())
      .catch(err => this.TmrToast.exception(err))
      .finally(() => (this.printing = false))
  }
  async printMultipleTimes(type, resList, labelQuantity) {
    let data = this.printBulkPostData(resList)
    for (let i = 0; i < labelQuantity; i++) {
      await this.TmrApi.post(`${type}.printBulk`, null, data)
    }
  }

  printSuccess() {
    this.TmrToast.success('PRINTMODAL.SUCCESS')
    return this.cancel()
  }

  quantitiesControl() {
    this.invalidQuantity = false
    this.resources.forEach(r => {
      if (!/[0-9]/.test(r.quantity)) this.invalidQuantity = true
    })
  }
}

export { tmrPrintMultipleModalConfig as config }
export const factory = ['TmrPrintMultipleModal', TmrPrintMultipleModalFactory]
