import { resourceListBindings } from 'tmr/components/resourceList/resourceListHelper'
import templateUrl from './sessions.html'

/**
 * Sessions report route
 *
 * @namespace routes.ReportSessions
 * @type {TmrRoute}
 */
export const route = {
  name: 'report.sessions.list',
  label: 'REPORT.SESSIONS.TITLE',
  url: '/sessions',
  component: 'tmrWorkLogSessions',
  weight: 10,
  resourceType: 'Session',
  accessControl: [
    'Loglavorazione.capability.list',
    'Loglavorazione.capability.admin',
  ],
  resourceList: {
    searchParameters: [
      'user.id',
      'orderCodeLike',
      'operazione.code',
      'economia.code',
      'startDate.from',
      'startDate.to',
      'status',
    ],
  },
  params: {
    limit: '250',
    selectedSessions: {
      value: [],
      dynamic: true,
    },
    orderBy: {
      value: 'START_DATE',
    },
  },
}

/**
 * Component for {@link routes.tmrReportSessions Sessions report route}
 *
 * @type {ngComponent}
 */
export const tmrWorkLogSessions = {
  ngType: 'component',
  templateUrl,
  bindings: resourceListBindings(),
  controller: 'tmrWorkLogSessionsController',
}

/**
 * Controller of component {@link tmrReportSessionsController}.
 *
 * @type {ngController}
 */
export class tmrWorkLogSessionsController {
  static ngType = 'controller'
  constructor(
    $state,
    $mdDialog,
    TmrToast,
    TmrPromptModal,
    Session,
    SessionMovement
  ) {
    'ngInject'
    Object.assign(this, {
      $state,
      $mdDialog,
      TmrToast,
      TmrPromptModal,
      Session,
      SessionMovement,
    })
  }

  $onInit() {
    // Extend row attribute with parent session
    this.pageSize = 250

    this.editedResources = this.resources.map(session => {
      //se una sessione non ha righe ne inserisco una fake per effettutare lo stesso i calcoli dei tempi
      if (!session.rows || session.rows.length === 0) {
        session.rows = [
          {
            fake: true,
          },
        ]
      }

      session.rows = session.rows.map(row => {
        let tempiMediMinutiRaw = 0
        let totalTimeMinutiRaw = 0

        row.session = session

        if (row.session.totalTime) {
          row.session.totalTimeMin = secondsToMinutesToString(
            row.session.totalTime
          )
          totalTimeMinutiRaw = secondsToMinutes(row.session.totalTime)
        }

        row.session.rows.forEach(row => {
          tempiMediMinutiRaw += row.tempiMedi
        })

        row.session.tempiMediMin = isNaN(tempiMediMinutiRaw)
          ? ' '
          : secondsToMinutesToString(tempiMediMinutiRaw * 60)

        row.session.performance =
          tempiMediMinutiRaw !== 0
            ? (tempiMediMinutiRaw / totalTimeMinutiRaw) * 100
            : 0

        row.session.performance = isNaN(row.session.performance)
          ? ' '
          : row.session.performance.toFixed(2) + ' %'

        row.session.rowsAmount = row.session.rows.reduce(
          (acc, curr) => acc + (curr.fake ? 0 : curr.mezzoCapo ? 0.5 : 1),
          0
        )
      })
      return session
    })

    function secondsToMinutesToString(seconds) {
      let timeSeconds = Math.floor(seconds % 60).toString()
      if (timeSeconds.length === 1) timeSeconds = `0${timeSeconds}`
      return `${Math.floor(seconds / 60)}:${timeSeconds} min.`
    }

    function secondsToMinutes(seconds) {
      return seconds / 60
    }

    // Flatten all rows
    this.workingRows = this.editedResources.flatMap(session => session.rows)

    console.log(
      '****** Resources **********',
      this.resources,
      '****************'
    )
    console.log(
      '****** Rows **********',
      this.resources.flatMap(session => session.rows),
      '****************'
    )
    console.log(
      '****** workingRows **********',
      this.workingRows,
      '****************'
    )
  }

  getCommessaLabel(commessa) {
    if (commessa.product)
      return `${commessa.pacco} ${commessa.product.description}`
    return commessa.pacco
  }

  getUserLabel(user) {
    if (user.attributes.matricola)
      return `${user.username} (${user.attributes.matricola})`
    return user.username
  }

  getOperazioneLabel(op) {
    if (op.description) return `${op.code} ${op.description}`
    if (op.code) return `${op.code}`
    return op.id
  }

  getEconomiaLabel(eco) {
    if (eco.description) return `${eco.code} ${eco.description}`
    if (eco.code) return `${eco.code}`
    return eco.id
  }
}
