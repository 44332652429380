import { factory as CoreUserFactory } from 'tmr/resources/user/user'

/**
 * Extends the core Item resource with new methods
 *
 * @name User
 * @class
 * @augments TmrResource
 */
function UserFactory(
  TmrResource,
  TmrApi,
  Place,
  Role,
  TmrTransformResourceAction
) {
  'ngInject'
  let CoreUser = CoreUserFactory[1](
    TmrResource,
    TmrApi,
    Place,
    Role,
    TmrTransformResourceAction
  )

  function getCustomLabel() {
    const matricola =
      this.attributes && this.attributes['matricola']
        ? ` (${this.attributes['matricola']})`
        : ''
    return `${this.$getFullName(this.email)}${matricola}`
  }

  // 3. Copy the new methods to the original item resource
  Object.assign(CoreUser, { getCustomLabel })
  Object.assign(CoreUser.prototype, {
    $getCustomLabel: getCustomLabel,
  })
  // 4. Export the modified original item resource
  return CoreUser
}

export const factory = ['User', UserFactory]
