import { register } from 'tmr/tmr/ngModuleRegistry'

// See src/app/examples/index.js for examples

// Themeing.
import './theme/theme.scss'

// Constants.
import * as apiPrefix from './constants/apiPrefix'
import * as appName from './constants/appName'
import * as defaultApiEndpoints from './constants/defaultApiEndpoints'

// Resources
import * as schedaTecnicaResource from './resources/schedatecnica.js'
import * as sessionResource from './resources/session'
import * as operazioneResource from './resources/operazione'
import * as economiaResource from './resources/economia'
import * as userResource from './resources/user'
import * as customProductionOrderRowPrintSessionResource from './resources/customProductionOrderRowPrintSession'

// Components
import * as printMultipleModal from './components/modals/printMultipleModal/printMultipleModal'
import * as sessionBulkOperations from './routes/report/sessions/sessions.bulkOperations'
import * as bulkEditModal from './components/modals/bulkEditModal/bulkEditModal'
import * as resourceActionDeleteOverride from './components/resourceActions/resourceActionDelete/resourceActionDeleteOverride'
// Template
import './routes/report/sessions/session.view.html'
import './routes/report/sessions/sessions.html'
import './routes/operations/print/printOrderRows'
import './routes/report/sessions/form.html'
import './routes/operations'
import './routes/report/schedetecniche/schedetecniche.html'
import './routes/report/schedetecniche/schedetecniche.view.html'
import './routes/report/schedetecniche/form.html'

import './components/logoSidebar/logoSidebar.html'

// Routes
import * as session from './routes/report/sessions/sessions'
import * as sessionView from './routes/report/sessions/session.view'
import * as printOrderRow from './routes/operations/print/printOrderRows'
import * as sessionForm from './routes/report/sessions/form.js'
import * as login from './routes/login/login'
import * as schedeTecniche from './routes/report/schedetecniche/schedetecniche'
import * as schedeTecnicheView from './routes/report/schedetecniche/schedetecniche.view'
import * as schedeTecnicheForm from './routes/report/schedetecniche/form'

// Add everything to the module registry.
register(
  'tmr.brioni',
  apiPrefix,
  appName,
  defaultApiEndpoints,
  session,
  sessionView,
  sessionBulkOperations,
  printOrderRow,
  login,
  sessionForm,
  schedeTecniche,
  schedaTecnicaResource,
  schedeTecnicheView,
  schedeTecnicheForm,
  sessionResource,
  operazioneResource,
  economiaResource,
  userResource,
  customProductionOrderRowPrintSessionResource,
  printMultipleModal,
  bulkEditModal,
  resourceActionDeleteOverride
)
