/**
 * Resource that represents an optional configuration key-value pair stored in the database.
 *
 * @name Session
 * @class
 * @augments TmrResource
 */
export const factory = [
  'Session',
  /** @ngInject */ (TmrApi, TmrResource, User) => {
    let actions = {
      adminSave: {
        method: 'POST',
        url: TmrApi.endpoint('Session.adminSave'),
      },
      adminUpdate: {
        method: 'POST',
        url: TmrApi.endpoint('Session.adminUpdate'),
      },
      adminBulkUpdate: {
        method: 'POST',
        url: TmrApi.endpoint('Session.adminBulkUpdate'),
      },
      rows: {
        method: 'GET',
        url: TmrApi.endpoint('Session.rows'),
        isArray: true,
      },
    }

    let referencedProperties = [{ property: 'user', resource: User }]

    let defaultValues = () => ({
      attributes: {},
      rows: [],
    })

    let Session = TmrResource(
      'Session',
      actions,
      referencedProperties,
      defaultValues
    )

    Object.assign(Session, {})

    return Session
  },
]
