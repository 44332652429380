import { tmrResourceActionDeleteController } from 'tmr/components/resourceActions/resourceActionDelete/resourceActionDelete'

/**
 * Overrides the controller of component {@link tmrResourceActionDeleteController}.
 *
 * @type {ngController}
 */
export class tmrResourceActionDeleteControllerOverride extends tmrResourceActionDeleteController {
  /**
   * Optional constructor override.
   * If present, must call super() passing all necessary injected services.
   */
  constructor($state, TmrSearchFormFilters) {
    'ngInject'
    super($state)
    this.message = 'Started! ActionDeleteOverride!'
    this.TmrSearchFormFilters = TmrSearchFormFilters
  }

  /**
   * Overrides tmrResourceActionDeleteController.$onInit()
   *
   * You can access the parent class method this way:
   * super.methodName(args...).
   */
  $onInit() {
    super.$onInit()
    console.log(this.message)
    this.redirectParams =
      this.redirectParams ?? this.TmrSearchFormFilters.params
  }
}
const tmrResourceActionDeleteDirective = /*@ngInject*/ $delegate => {
  $delegate[0].controller = 'tmrResourceActionDeleteControllerOverride'
  return $delegate
}

export const decorator = [
  'tmrResourceActionDeleteDirective',
  tmrResourceActionDeleteDirective,
]
