import './bulkEditModal.scss'
import templateUrl from './bulkEditModal.html'
import _ from 'lodash'
/**
 * Configures a bulk edit Modal using $mdPanel
 *
 * @type {ngConfig}
 * @ngInject
 */

function tmrBulkEditModalConfig($mdPanelProvider) {
  $mdPanelProvider.definePreset('bulkEditModalCustom', {
    templateUrl,
    controller: 'tmrBulkEditModalCustomController',
    controllerAs: '$ctrl',
    panelClass: 'tmr-modal tmr-bulk-edit-modal md-whiteframe-1dp',
    clickOutsideToClose: true,
    escapeToClose: true,
    hasBackdrop: true,
  })
}

/**
 * @type {ngFactory}
 * @ngInject
 */
function TmrBulkEditModalFactory($mdPanel) {
  /**
   * Simplifies the usage of the `bulkEditModal` panel defined in {@link tmrBulkEditModalConfig}
   *
   * @example <caption>In a controller</caption>
   * this.TmrBulkEditModal($event, { resource: this.resource })
   *
   * @type {ngService}
   * @param {Event} openFrom
   * @type {ngService}
   */
  return function TmrBulkEditModal(
    openFrom,
    selectedSessions,
    resource,
    state
  ) {
    return $mdPanel.open('bulkEditModalCustom', {
      openFrom,
      position: $mdPanel.newPanelPosition().absolute().center(),
      resolve: {
        selectedSessions: () => {
          return selectedSessions
        },
        resource: () => {
          return resource
        },
        state: () => {
          return state
        },
        operazioni: /** @ngInject */ Operazione =>
          Operazione.query({ orderBy: 'CODE', asc: 'true' }).$promise,
        economie: /** @ngInject */ Economia =>
          Economia.query({ orderBy: 'CODE', asc: 'true' }).$promise,
        users: /** @ngInject */ User =>
          User.query({ orderBy: 'USERNAME', asc: 'true' }).$promise,
      },
    })
  }
}

/**
 * Controller for the panel preset bulkEditModal
 *
 * @type {ngController}
 * @see TmrBulkEditModal
 */
export class tmrBulkEditModalCustomController {
  static ngType = 'controller'

  // Locals
  /** @type {object[]} */

  /** @ngInject */ constructor(
    TmrApi,
    TmrSerialQ,
    TmrToast,
    mdPanelRef,
    User,
    Operazione,
    Economia,
    ProductionOrderRow,
    Session,
    $state,
    $window
  ) {
    this.TmrApi = TmrApi
    this.TmrSerialQ = TmrSerialQ
    this.TmrToast = TmrToast
    this.mdPanelRef = mdPanelRef
    Object.assign(this, {
      $state,
      $window,
      User,
      Operazione,
      Economia,
      ProductionOrderRow,
      Session,
    })
  }

  $onInit() {
    console.log('Modal Resources', this.resources)
  }

  close() {
    this.mdPanelRef.close()
    this.state.selectedSessions = []
    this.$state.reload()
  }

  remove(resource) {
    this.selectedSessions.splice(
      this.selectedSessions.findIndex(res => res === resource),
      1
    )
    if (!this.resources.length) return this.cancel()
    console.log('Removed Resource', this.selectedSessions)
  }

  getOrderRows(pacco) {
    return this.ProductionOrderRow.search({
      value: pacco,
    }).$promise.then(rows => {
      return _.chain(rows).map('pacco').uniq().value()
    })
  }

  filterUsers(value) {
    return this.users.filter(
      u =>
        u.email.toLowerCase().includes(value.toLowerCase()) ||
        u.username.toLowerCase().includes(value.toLowerCase()) ||
        _.get(u.attributes, 'matricola', []).includes(value.toLowerCase())
    )
  }

  filterEconomie(value) {
    return this.economie.filter(eco =>
      eco.$getLabel().toLowerCase().includes(value.toLowerCase())
    )
  }

  filterOperazioni(value) {
    return this.operazioni.filter(op =>
      op.$getLabel().toLowerCase().includes(value.toLowerCase())
    )
  }

  setOperazione(value) {
    this.resource.codiceOperazione = _.get(value, 'code', null)
  }

  setEconomia(value) {
    this.resource.codiceMotivoEconomia = _.get(value, 'code', null)
  }

  onSubmit() {
    this.updateSessions()
      .then(() => {
        console.log('All sessions updated successfully')
        this.close()
      })
      .catch(error => {
        console.error('Error updating sessions:', error)
        this.TmrToast.exception(error)
      })
  }

  disableButton() {
    if (
      !this.orderCode &&
      !this.resource.user &&
      !this.resource.codiceMotivoEconomia &&
      !this.resource.codiceOperazione
    ) {
      return true
    }

    return false
  }

  updateSessions() {
    if (
      !this.orderCode &&
      !this.resource.user &&
      !this.resource.codiceMotivoEconomia &&
      !this.resource.codiceOperazione
    ) {
      this.TmrToast.error('Niente da modificare')
      return
    }

    const sessionsIds = this.selectedSessions.map(session => session.id)

    return new Promise((resolve, reject) => {
      const promises = []

      const promise = new Promise((resolve, reject) => {
        this.TmrApi.post(
          'Session.adminBulkUpdate',
          {},
          {
            ids: sessionsIds,
            commessa: this.orderCode ?? '',
            operatore: this.resource.user ? this.resource.user.email : '',
            codiceMotivoEconomia: this.resource.codiceMotivoEconomia ?? '',
            codiceOperazione: this.resource.codiceOperazione ?? '',
          },
          {
            headers: { 'Content-Type': 'application/json' },
          }
        )
          .then(resolve)
          .catch(reject)
      })
      promises.push(promise)

      // Resolve all promises when all requests are completed
      Promise.all(promises)
        .then(() => {
          resolve() // Resolve the main promise
        })
        .catch(reject) // Reject if any of the requests fail
    })
  }
}

export { tmrBulkEditModalConfig as config }
export const factory = ['TmrBulkEditModal', TmrBulkEditModalFactory]
