/**
 * Resource that represents a Operazione.
 *
 * @name Operazione
 * @class
 * @augments TmrResource
 */
function OperazioneFactory(TmrResource) {
  'ngInject'
  let Operazione = TmrResource('Operazione')

  /**
   * Override default label property.
   *
   * @method Operazione#getLabelProperty
   */
  function $getLabel() {
    return `${this.code} ${this.description}`
  }
  Object.assign(Operazione.prototype, {
    $getLabel,
  })

  return Operazione
}

export const factory = ['Operazione', OperazioneFactory]
