import { resourceListBindings } from 'tmr/components/resourceList/resourceListHelper'
import templateUrl from './schedetecniche.html'

/**
 * Schede Tecniche report route
 *
 * @namespace routes.ReportSchedeTecniche
 * @type {TmrRoute}
 */
export const route = {
  name: 'report.schedetecniche.list',
  label: 'REPORT.SCHEDETECNICHE.TITLE',
  url: '/schedetecniche',
  component: 'tmrSchedeTecniche',
  weight: 10,
  resourceType: 'SchedaTecnica',
  accessControl: ['SchedaTecnica.view', 'SchedaTecnica.upload'],
  resourceList: {
    searchParameters: ['productCodeLike', 'season', 'type'],
  },
}

/**
 * Component for {@link routes.tmrReportSchedeTecniche Schede Tecniche report route}
 *
 * @type {ngComponent}
 */
export const tmrSchedeTecniche = {
  ngType: 'component',
  controller: 'tmrAdminSchedeTecnicheController',
  templateUrl,
  bindings: resourceListBindings(),
}

/**
 * Controller of component {@link tmrAdminSchedeTecnicheController}.
 *
 * @type {ngController}
 */
export class tmrAdminSchedeTecnicheController {
  static ngType = 'controller'
  constructor(
    $state,
    $mdDialog,
    TmrToast,
    TmrPromptModal,
    Session,
    SessionMovement
  ) {
    'ngInject'
    Object.assign(this, {
      $state,
      $mdDialog,
      TmrToast,
      TmrPromptModal,
      Session,
      SessionMovement,
    })
  }

  $onInit() {
    this.schedeType = {
      prototipo: 'prototipo',
      campionario: 'campionario',
      campionario_pr: 'campionario_pr',
      produzione: 'produzione',
      produzione_pr: 'produzione_pr',
      vip: 'vip',
    }
  }

  getSchedaTecnicaLabel(st) {
    return st.productCode
  }
}
