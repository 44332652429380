/**
 * Resource that represents a Production Order Row Print Session (a job).
 *
 * @name CustomProductionOrderRowPrintSession
 * @class
 * @augments TmrResource
 */
function CustomProductionOrderRowPrintSessionFactory(
  TmrResource,
  Printer,
  User
) {
  'ngInject'
  let referencedProperties = [
    { property: 'printer', resource: Printer },
    { property: 'creationUser', resource: User },
  ]
  let CustomProductionOrderRowPrintSession = TmrResource(
    'CustomProductionOrderRowPrintSession',
    [],
    referencedProperties
  )

  Object.assign(CustomProductionOrderRowPrintSession, {
    getPrintedResourceProperty,
  })
  Object.assign(CustomProductionOrderRowPrintSession.prototype, {
    $getPrintedResourceProperty: getPrintedResourceProperty,
    $getPrintedResource,
  })

  /**
   * Returns the property that holds the printed row.
   *
   * @method CustomProductionOrderRowPrintSession#$getPrintedResourceProperty
   */
  function getPrintedResourceProperty() {
    return 'CustomProductionOrderRow'
  }

  /**
   * Returns printed row.
   *
   * @method CustomProductionOrderRowPrintSession#$getPrintedResource
   */
  function $getPrintedResource() {
    return this[this.$getPrintedResourceProperty()]
  }

  return CustomProductionOrderRowPrintSession
}

export const factory = [
  'CustomProductionOrderRowPrintSession',
  CustomProductionOrderRowPrintSessionFactory,
]
