// import './tagInfo.scss'
import { tmrOperationController } from 'tmr/controllers/operation/operation'
import templateUrl from './powerBi.html'
import icon from 'tmr/routes/operations/inbound/inbound.svg'

/**
 * Stone Counter Operation route.
 *
 * @namespace routes.OperationPowerBi
 * @type {TmrRoute}
 */
export const route = {
  name: 'operations.powerBi',
  label: 'OPERATIONS.POWERBI.TITLE',
  url: '/power-bi',
  component: 'tmrOperationPowerBi',
  accessControl: ['Resource.PowerBi.list'],
  weight: 6,
  icon,
}

/**
 * Stone Counter Operation component.
 *
 * @type {ngComponent}
 */
export const tmrOperationPowerBi = {
  ngType: 'component',
  templateUrl,
  controller: 'brioniOperationPowerBiController',
}

/**
 * Controller of component {@link brioniOperationPowerBiController}.
 *
 * @type {ngController}
 */
export class brioniOperationPowerBiController extends tmrOperationController {
  constructor(
    $mdDialog,
    TmrToast,
    TmrMovementHelper,
    TmrOperationHelper,
    $state,
    TmrApi,
    $http,
    $sce
  ) {
    'ngInject'
    super($mdDialog, TmrToast, TmrMovementHelper, TmrOperationHelper)
    Object.assign(this, {
      $state,
      TmrApi,
      $http,
      $sce,
    })
  }

  $onInit() {
    this.getUrl().then(url => (this.url = this.$sce.trustAsResourceUrl(url)))
  }

  getUrl() {
    const apiKey = 'c9b54868-e223-4c1b-8807-ca7d6bc69185'
    const reportId = '88a4ebe1-ca3e-4c16-83dc-831f0013bc4d'
    return this.$http
      .get(
        `https://temeraapi-embedapi.azurewebsites.net/api/GetReports?apiKey=${apiKey}&reportId=${reportId}`
      )
      .then(res => {
        return res.data.embedUrlReport
      })
  }
}
