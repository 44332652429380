import templateUrl from './sessions.bulkOperations.html'

/**
 * Select box with bulk actions for sessions. Used in the Sessions report
 * Expects a "selectedSessions" stateParam to work
 * @type {ngComponent}
 */
export const tmrReportSessionsBulkOperations = {
  ngType: 'component',
  templateUrl,
  controller: 'tmrReportSessionsBulkOperationsController',
  bindings: {
    resource: '<',
  },
}

/**
 * Controller of component {@link tmrReportSessionsBulkOperations}
 * Expects a "selectedSessions" stateParam
 * @type {ngController}
 */
export class tmrReportSessionsBulkOperationsController {
  static ngType = 'controller'

  /** @ngInject */ constructor(
    $mdMenu,
    $stateParams,
    TmrBulkEditModal,
    TmrToast,
    $state
  ) {
    this.$mdMenu = $mdMenu
    this.$stateParams = $stateParams
    this.TmrBulkEditModal = TmrBulkEditModal
    this.TmrToast = TmrToast
    this.$state = $state
  }

  $onInit() {}

  clearSelection() {
    this.$state.go('.', { selectedSessions: [] })
  }

  openDialog($event) {
    console.log('State', this.$stateParams)
    return this.TmrBulkEditModal(
      $event,
      this.$stateParams.selectedSessions,
      this.resource,
      this.$stateParams
    )
  }
}
