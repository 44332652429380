import { tmrResourceFormController } from 'tmr/controllers/resourceForm/resourceForm'
import templateUrl from './form.html'

/**
 * Add Schede Tecniche route override
 *
 * @namespace routes.ReportSchedeTecnicheFormAdd
 * @type {TmrRoute}
 */
let addRoute = {
  name: 'report.schedetecniche.add',
  accessControl: ['SchedaTecnica.upload'],
}

/**
 * Edit Schede Tecniche route override
 *
 * @namespace routes.ReportSchedeTecnicheFormEdit
 * @type {TmrRoute}
 */
let editRoute = {
  name: 'report.schedetecniche.view.edit',
  accessControl: ['SchedaTecnica.upload'],
}

export const routes = [addRoute, editRoute]

/**
 * Customer add/edit form component.
 *
 * @namespace tmrAdminSchedeTecnicheForm
 * @type {ngComponent}
 */
export const tmrAdminSchedaTecnicaForm = {
  ngType: 'component',
  templateUrl,
  controller: 'tmrAdminSchedeTecnicheFormController',
  bindings: {
    resource: '<',
    toast: '<',
  },
}

/**
 * Controller of component {@link tmrAdminSchedeTecnicheForm}.
 *
 * @type {ngController}
 */
export class tmrAdminSchedeTecnicheFormController extends tmrResourceFormController {
  constructor($scope, $state, $stateParams, TmrInvalidFormError, TmrToast) {
    'ngInject'
    super($scope, $state, $stateParams, TmrInvalidFormError, TmrToast)
  }
  $onInit() {
    super.$onInit()

    this.maxFileSize = '5000000'
    this.schedeType = {
      prototipo: 'prototipo',
      campionario: 'campionario',
      campionario_pr: 'campionario_pr',
      produzione: 'produzione',
      produzione_pr: 'produzione_pr',
      vip: 'vip',
    }
    this.pdfFile = null
  }
  save() {
    return this.resource.$uploadScheda(this.pdfFile)
  }
  onFileChange({ files, clearFiles }) {
    if (files.length === 0) {
      this.pdfFile = null
      this.clearPdfFile = null
    } else {
      this.pdfFile = files[0]
      this.clearPdfFile = () => clearFiles()
      this.replaceImage = true
    }
  }
}
