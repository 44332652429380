import templateUrl from './schedetecniche.view.html'
import './form-extension.scss'

/**
 * Schede Tecniche report route
 *
 * @namespace routes.ReportSchedeTecnicheView
 * @type {TmrRoute}
 */
export const route = {
  name: 'report.schedetecniche.view',
  label: 'REPORT.SCHEDETECNICHE.VIEW.TITLE',
  component: 'tmrSchedeTecnicheView',
  abstract: false,
  accessControl: [
    'Loglavorazione.capability.edit, Loglavorazione.capability.view',
  ],
}

/**
 * Component for {@link routes.tmrReportSchedeTecnicheView Schede Tecniche View report route}
 *
 * @type {ngComponent}
 */
export const tmrSchedeTecnicheView = {
  ngType: 'component',
  accessControl: ['SchedaTecnica.view', 'SchedaTecnica.upload'],
  templateUrl,
  bindings: {
    resource: '<',
  },
}
