import { resourceListBindings } from 'tmr/components/resourceList/resourceListHelper'
import { tmrOperationBasePrintRoute } from 'tmr/routes/operations/print/print.route'
import { tmrOperationPrintController } from 'tmr/routes/operations/print/print.controller'

import templateUrl from './printOrderRows.html'

/**
 * Print order rows route
 *
 * @namespace routes.OperationPrintProductionOrderRows
 * @type {TmrRoute}
 */
export const route = tmrOperationBasePrintRoute({
  name: 'operations.print.productionOrderRows',
  label: 'OPERATIONS.PRINT.ORDERROWS.TITLE',
  url: '/print/orders',
  resourceType: 'ProductionOrderRow',
  resourceList: {
    searchParameters: [
      'printed',
      'order.id',
      'product.code',
      'size',
      'cuttingOrder',
      'progressive',
      'order.code',
      'conformation',
      'pack',
    ],
  },
  params: {
    orderBy: 'PROGRESSIVE',
    printed: 'all',
  },
  component: 'customTmrOperationPrintProductionOrderRows',
  accessControl: ['Operation.perform', 'Operation.PrintOrderRows.perform'],
  weight: 2,
})

/**
 * Print component
 *
 * @type {ngComponent}
 */
export const customTmrOperationPrintProductionOrderRows = {
  ngType: 'component',
  templateUrl,
  bindings: resourceListBindings(),
  controller: tmrOperationPrintController,
}
