/**
 * Resource that represents a SchedaTecnica.
 *
 * @name SchedaTecnica
 * @class
 * @augments TmrResource
 */
function SchedaTecnicaFactory(TmrResource, TmrApi, $sce) {
  'ngInject'
  let SchedaTecnica = TmrResource('SchedaTecnica')

  Object.assign(SchedaTecnica.prototype, {
    $uploadScheda,
    $trustUrl,
  })

  return SchedaTecnica

  /**
   * Uploads a an image for this product
   * @param {File} image
   */
  function $uploadScheda(file) {
    const formData = new FormData()
    if (file != null) {
      formData.append('file', file)
    }
    formData.append('productCode', this.productCode)
    formData.append('season', this.season)
    formData.append('type', this.type)
    formData.append('version', this.version)
    formData.append('cad', this.cad)
    return TmrApi.post('SchedaTecnica.upload', {}, formData, {
      // Set to undefined so the browser picks the right multipart/form-data boundary
      headers: { 'Content-Type': undefined },
    })
  }

  function $trustUrl() {
    return $sce.trustAsResourceUrl(this.link)
  }
}

export const factory = ['SchedaTecnica', SchedaTecnicaFactory]
