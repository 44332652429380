import { constant as defaultApiEndpoints } from 'tmr/constants/defaultApiEndpoints'

export const constant = [
  'tmrDefaultApiEndpoints',
  {
    ...defaultApiEndpoints[1],
    // Add custom endpoints here
    Session: 'loglavorazione/:id',
    'Session.close': 'loglavorazione/:id/close',
    'Session.filteredSearch': 'loglavorazione/filteredSearch',
    'Session.itemStatus': 'loglavorazione/:id/items/status',
    'Session.adminSave': 'loglavorazione/admin/create',
    'Session.adminUpdate': 'loglavorazione/admin/:id',
    'Session.adminBulkUpdate': 'loglavorazione/admin/bulkUpdate',
    'Session.rows': 'loglavorazione/rows',
    SchedaTecnica: 'schedetecniche/:id',
    'SchedaTecnica.upload': 'schedetecniche/upload',
    Operazione: 'operazione/:id',
    Economia: 'motivoeconomia/:id',
    ProductionOrderRow: 'custom/productionOrderRows/:id',
    'ProductionOrderRow.printable': 'custom/productionOrderRows/printer',
    'ProductionOrderRow.printableCount':
      'custom/productionOrderRows/printer/count',
    CustomProductionOrderRowPrintSession: 'customProductPrintSession/:id',
  },
]
